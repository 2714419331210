import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip from "@mui/material/Tooltip";

const CommonAccordion = ({ heading, content }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        {/* <Tooltip title="click to expand"> */}
        <strong>{heading}</strong>
        <span>(click to open & close)</span>
        {/* </Tooltip> */}
      </AccordionSummary>
      <AccordionDetails>
        {Array.isArray(content) ? (
          <span>
            {content?.map((info) => (
              <p key={info}>{info}</p>
            ))}
          </span>
        ) : (
          content
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default CommonAccordion;
